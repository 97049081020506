import Vue from "vue/dist/vue";
import FieldErrorBlock from "./FieldErrorBlock.vue";
import {trans} from "../../../common/Translation/Translation";

export default Vue.component('ConfirmationPasswordField', {
    data() {
        return {
            value: null,
            isSecret: true
        };
    },

    components: {
        FieldErrorBlock
    },

    computed: {
        type() {
            return (this.isSecret) ? 'password' : 'text';
        },

        isIdenticalToNew() {
            const newPassword = this.$parent.getNewPasswordField();

            if (newPassword === null) {
                return false;
            }

            return (newPassword.value === this.value);
        },

        isValid() {
            return this.isIdenticalToNew;
        }
    },

    methods: {
        getFieldErrorBlock() {
            return this.$refs['field-error-block'] || null;
        },

        update(event) {
            Vue.set(this, 'value', event.target.value);

            this.getFieldErrorBlock().clearErrors();
        },

        focusout() {
            const fieldErrorBlock = this.getFieldErrorBlock();

            fieldErrorBlock.clearErrors();

            if (!this.isIdenticalToNew) {
                fieldErrorBlock.addError('password.wrong-confirmation', trans('validators.password.wrong-confirmation'));
            }
        },

        reveal() {
            Vue.set(this, 'isSecret', false);
        },

        hide() {
            Vue.set(this, 'isSecret', true);
        }
    },

    updated() {
        this.$root.$forceUpdate();
    }
})

