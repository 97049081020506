import Vue from "vue/dist/vue";
import NewPasswordField from "./NewPasswordField";
import ConfirmationPasswordField from "./ConfirmationPasswordField";
import FieldErrorBlock from "./FieldErrorBlock.vue";

export default Vue.component('PasswordForm', {
    components: {
        NewPasswordField,
        ConfirmationPasswordField,
        FieldErrorBlock
    },

    computed: {
        arePasswordsValid() {
            const
                newPassword = this.getNewPasswordField(),
                confirmationPassword = this.getConfirmationPasswordField()
            ;

            if (newPassword === null || confirmationPassword === null) {
                return false;
            }

            return (newPassword.isValid && confirmationPassword.isValid);
        }
    },

    methods: {
        getNewPasswordField() {
            return this.$refs['new-password-field'] || null;
        },

        getConfirmationPasswordField() {
            return this.$refs['confirmation-password-field'] || null;
        },

        getFieldErrorBlock() {
            return this.$refs['field-error-block'] || null;
        }
    },

    updated() {
        this.$root.getSubmitButton().$forceUpdate();
    }
});
