import $ from 'jquery';
import {scroll} from "../common/functions";

///***  REMOVE PAGE LOADER AFTER PAGE LOAD  ***///
$(window).on('load', function () {

    if ($('.error', 'form').length > 0) {
        scroll($('.error').first(), 0);
    }
});


$(document).ready(function () {

    ///***  CONFIG MOBILE CLASSES  ***///

    // SCROLL TOP ON MENU ITEM CLICK
    $('.header-menu .menu-item a').on('click', function (event) {
        var id = $(this).attr('href').split('#')[1];

        $('.header-menu .menu-item ').removeClass('active')
        $(this).parents('.menu-item').addClass('active')

        $('header .sidenav-mobile').removeClass('open');
        $('body').removeClass('overlay-show').find('.header-search-block').removeClass('hidden');

        if ($('body').hasClass('mobile')) {

            var sroll_position_clicked = 76;
        } else {
            var sroll_position_clicked = 128;

        }

        if ($('#' + id).length) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $('#' + id).offset().top - sroll_position_clicked
            }, 0);
        }

    });


    if ($('.user-login-form').length) {

        // Play with select change
        $('form .form-submit').on('click', function (e) {
            // todo en attendant que le js de la page "init mdp" soit fait
            if ($('[data-page^="password-reset"]').length > 0) {
                return true;
            }

            var form = $(this).parents('form'),
                required = form.find('.required'),
                result = true;

            required.each(function () {
                var parent = $(this).parents('.form-item');

                if ($(this).attr('type') == 'checkbox') {
                    if (!$(this).is(":checked")) {
                        result = false;
                        $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                    } else {
                        $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
                    }
                } else {
                    if (!$(this).val()) {
                        result = false;
                        $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                    } else {
                        $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
                    }
                }
            });

            if (result == false) {
                return false;
            } else {
                $(this).parents('.submit-wrapper').find('.loading-spinner-container').show();
            }

        });

        if ($('form.user-login-form').length || $('form.user-pass').length) {
            if ($('div[data-drupal-messages]').length) {
                $('div[data-drupal-messages] .messages:not(.messages--warning) >div').each(function () {
                    $('form').find('.validation-form').removeClass('hide').append($(this).html())
                })
            }
        }


        // REMOVE ERROR MESSAGE ON INPUT
        $('.form-item input,.form-item textarea').on('input', function () {
            if ($(this).val()) {
                $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
            } else {
                $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
            }
        });

        // DETECT VALID SUBMISSIONS MESSAGE AND APEND IN DIV
        if ($('div[data-drupal-messages]').length) {
            $('div[data-drupal-messages] .messages:not(.messages--warning) p').each(function () {
                $('form').find('.validation-form').removeClass('hide').append($(this).html())
            })
        }

        if ($('.path-user').length) {
            $('form .form-item').each(function () {
                var label = $(this).find('label').text()
                $(this).find('input').attr('placeholder', label)
            })
        }

        $('form .form-item .form-textarea').removeAttr('aria-required')

    }

    if ($('.reset-pwd').length) {
        console.log('tu rentress')

        $('.form-item-email').append('<span class="error-message" data-error-format="Le format de l\'email n\'est pas valide" data-error="Ce champ est obligatoire" data-error-mail="Le format de l\'email est incorrect"></span>')

        // Play with select change
        $('form .form-submit').on('click', function (e) {

            var form = $(this).parents('form'),
                required = form.find('.form-item-email input'),
                result = true;

            required.each(function () {
                var re = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                if (!$(this).val()) {
                    result = false;
                    $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                } else {
                    if (re.test($(this).val())) {
                        $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
                    } else {
                        $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error-mail'))
                        result = false;
                    }

                }
            });


            if (result == false) {
                return false;
            } else {
                $(this).parents('.submit-wrapper').find('.loadingio-spinner-spin-6whddfth6ph').show();
            }

        });


        // REMOVE ERROR MESSAGE ON INPUT
        $('.form-item input,.form-item textarea').on('input', function () {
            if ($(this).val()) {
                $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
            } else {
                $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
            }
        });


    }
    // FORM RESET PWD

    if ($('form[name=change_password_form]').length) {


        // Play with select change
        $('form .form-submit').on('click', function (e) {

            var form = $(this).parents('form'),
                required = form.find('input[type=password]'),
                result = true,
                new_pwd = form.find('.form-item.first-pwd input'),
                new_pwd_confirm = form.find('.form-item.second-pwd input')

            required.each(function () {
                if (!$(this).val()) {
                    result = false;
                    $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                } else {
                    $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
                }
            });

            if (new_pwd.val() != new_pwd_confirm.val()) {
                result = false;
                $(this).parents('form').find('.password-differents').addClass('error').html($(this).parents('form').find('.password-differents').attr('data-error'))
            }

            if (result == false) {
                return false;
            } else {
                $(this).parents('.submit-wrapper').find('.loadingio-spinner-spin-6whddfth6ph').show();
            }

        });


        $('.form-item.first-pwd input').on('input', function () {
            var inputVal = $(this).val();

            if (!$(this).val()) {
                $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                $(this).parents('form').find('.password-differents').removeClass('error').html('')

            } else {
                $(this).parents('.form-item').removeClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').html(''))

                if ($('.form-item.second-pwd input').val()) {
                    if (inputVal == $('.form-item.second-pwd input').val()) {
                        $(this).parents('form').find('.password-differents').removeClass('error').html('')
                    } else {
                        $(this).parents('form').find('.password-differents').addClass('error').html($(this).parents('form').find('.password-differents').attr('data-error'))
                    }
                }


            }

        });

        $('.form-item.second-pwd input').on('input', function () {
            var inputVal = $(this).val();

            if (!$(this).val()) {
                $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                $(this).parents('form').find('.password-differents').removeClass('error').html('')

            } else {
                $(this).parents('.form-item').removeClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').html(''))

                if ($('.form-item.first-pwd input').val()) {

                    if (inputVal == $('.form-item.first-pwd input').val()) {
                        $(this).parents('form').find('.password-differents').removeClass('error').html('')
                    } else {
                        $(this).parents('form').find('.password-differents').addClass('error').html($(this).parents('form').find('.password-differents').attr('data-error'))
                    }
                }


            }

        });


        $('form .form-item').each(function () {
            var label = $(this).find('label').text()
            $(this).find('input').attr('placeholder', label)
        })
    }

    $('.show-pwd').each(function () {
        $(this).on('click', function () {

            if ($(this).hasClass('active')) {
                $(this).removeClass('active')
                $(this).parents('.form-item').find('input').attr('type', 'password');
            } else {
                $(this).addClass('active')
                $(this).parents('.form-item').find('input').attr('type', 'text');

            }
        })
    })

    $(document).on('click', '.registration-block-header-close', function (e) {
        e.preventDefault();
        e.stopPropagation();

        if ($(this).hasClass('open')) {

            var slideBtn = $(this);
            $(this).removeClass('open').addClass('close').html('+')

            $(this).parent().parent().find('> .registration-block-content').slideUp('normal', function () {
                slideBtn.parent().parent().removeClass('open').addClass('close')
            });

        } else {
            $(this).parent().parent().removeClass('close').addClass('open')
            $(this).removeClass('close').addClass('open').html('-')
            $(this).parent().parent().find('> .registration-block-content').slideDown();
        }

    })

    // ON VIDEO CLIK HIDE MASK AND SHOW VIDEO
    $('.video-container').on('click', function () {
        $(this).find('.mask-video').hide();
        $(this).find('video').trigger('play');
    })

});

