import Vue from "vue/dist/vue";
import SpinnerLoader from "./SpinnerLoader.vue";

export default Vue.component('SubmitButton', {
    data() {
        return {
            isSending: false
        }
    },

    components: {
        SpinnerLoader
    },

    computed: {
        isDisabled() {
            const passwordForm = this.$parent.getPasswordForm();

            if (passwordForm === null) {
                return true;
            }

            return !passwordForm.arePasswordsValid;
        }
    },

    methods: {
        async click() {
            Vue.set(this, 'isSending', true);

            await this.$root.send();

            Vue.set(this, 'isSending', false);
        }
    }
});
