import $ from 'jquery';

$(document).ready(function() {

    ///***  SIDENAV  ***///
    if($('body').hasClass('mobile') || $('body').hasClass('tablet') || $('body').hasClass('tablet-large')){

        $('.sidenav-trigger').on('click', function(){
            var data_target = $(this).attr('data-target');
            $('.sidenav-mobile[data-content="' + data_target + '"]').addClass('open')

            if(!$('.overlay').length){
                $('body').append('<div class="overlay"></div>')
            }

            $('body').addClass('overlay-show').find('.header-search-block').addClass('hidden');


        });

        $('body').on('click',function(e){
            if ((!$('.sidenav-mobile').is(e.target) && $('.sidenav-mobile').has(e.target).length === 0 && !$('.sidenav-trigger').is(e.target) && $('.sidenav-trigger').has(e.target).length === 0) ) {
                $('.sidenav-mobile').removeClass('open');
                $('body').removeClass('overlay-show').find('.header-search-block').removeClass('hidden');
            }
        })

    }else{

        $('.sidenav-trigger').on('click', function(){
            var data_target = $(this).attr('data-target');
            $('[data-content="' + data_target + '"]').addClass('open')
            if(!$('.overlay').length){
                $('body').append('<div class="overlay"></div>')
            }

            $('body').addClass('overlay-show').find('.header-search-block').addClass('hidden');

        });

        $('body').on('click',function(e){

            if ((!$('.sidenav').is(e.target) && $('.sidenav').has(e.target).length === 0 && !$('.sidenav-trigger').is(e.target) && $('.sidenav-trigger').has(e.target).length === 0) ) {
                $('.sidenav').add('.sidenav-mobile').removeClass('open');
                $('body').removeClass('overlay-show').find('.header-search-block').removeClass('hidden');
            }
        })

    }

    $('.sidenav-close').on('click', function(){
        if($(this).parents('.sidenav-mobile').length){
            $(this).parents('.sidenav-mobile').removeClass('open');
        }else{
            $(this).parents('.sidenav').removeClass('open');
        }

        $(this).parents('header').removeClass('open')
        $('body').removeClass('overlay-show').find('.header-search-block').removeClass('hidden');

    });

});

