import Vue from "vue/dist/vue";
import FieldErrorBlock from "./FieldErrorBlock.vue";
import {ske} from "../../../common/Ske/Ske";
import {trans} from "../../../common/Translation/Translation";
import {is_empty, regex_quote} from "../../../common/functions";

export default Vue.component('NewPasswordField', {
    data() {
        return {
            value: null,
            isSecret: true
        };
    },

    components: {
        FieldErrorBlock
    },

    computed: {
        type() {
            return (this.isSecret) ? 'password' : 'text';
        },

        isFilled() {
            return !is_empty(this.value);
        },

        hasEnoughCharacters() {
            const minimum = ske().siteModule.passwordResetModule.minimumLength;

            if (minimum === null) {
                return true;
            }

            return (this.value.length >= minimum);
        },

        hasRightFormat() {
            const
                isDigitMandatory = ske().siteModule.passwordResetModule.isDigitMandatory,
                isLowercaseMandatory = ske().siteModule.passwordResetModule.isLowercaseMandatory,
                isUppercaseMandatory = ske().siteModule.passwordResetModule.isUppercaseMandatory,
                specialCharacters = ske().siteModule.passwordResetModule.allowedSpecialCharacters
            ;

            if (isDigitMandatory && !/\d/.test(this.value)) {
                return false;
            }

            if (isLowercaseMandatory && !/[a-z]/.test(this.value)) {
                return false;
            }

            if (isUppercaseMandatory && !/[A-Z]/.test(this.value)) {
                return false;
            }

            if (specialCharacters !== null) {
                let hasSpecialCharacter = false;

                for (const char of specialCharacters.split('')) {
                    if (this.value.includes(char)) {
                        hasSpecialCharacter = true;
                    }
                }

                if (!hasSpecialCharacter) {
                    return false;
                }

                const regex = new RegExp('[^a-zA-Z\\d' + regex_quote(specialCharacters) + ']');

                if (regex.test(this.value)) {
                    return false;
                }
            }

            return true;
        },

        isValid() {
            return (this.isFilled && this.hasEnoughCharacters && this.hasRightFormat);
        },
    },

    methods: {
        getFieldErrorBlock() {
            return this.$refs['field-error-block'] || null;
        },

        update(event) {
            Vue.set(this, 'value', event.target.value);

            this.getFieldErrorBlock().clearErrors();
        },

        focusout() {
            const fieldErrorBlock = this.getFieldErrorBlock();

            fieldErrorBlock.clearErrors();

            if (!this.isFilled) {
                fieldErrorBlock.addError('password.not-blank', trans('validators.password.not-blank'));
            } else if (!this.hasEnoughCharacters) {
                fieldErrorBlock.addError('password.length', trans('validators.password.length', {
                    '{{ limit }}': ske().siteModule.passwordResetModule.minimumLength
                }));
            } else if (!this.hasRightFormat) {
                fieldErrorBlock.addError('password.wrong-format', trans('validators.password.wrong-format'));
            }
        },

        reveal() {
            Vue.set(this, 'isSecret', false);
        },

        hide() {
            Vue.set(this, 'isSecret', true);
        }
    },

    updated() {
        this.$root.$forceUpdate();
    }
})

