import Vue from "vue/dist/vue";
import PasswordForm from "./components/PasswordForm";
import SubmitButton from "./components/SubmitButton";
import DotsLoader from "./components/DotsLoader.vue";
import FieldErrorBlock from "./components/FieldErrorBlock.vue";
import axios from "axios";
import {ske_load} from "../../common/Ske/Ske";
import {trans_load} from "../../common/Translation/Translation";

export class PagePasswordReset {
    static initialize() {
        this.vue = new Vue({
            el: "#password-reset-form",

            data() {
                return {
                    isLoaded: false
                };
            },

            components: {
                DotsLoader,
                PasswordForm,
                SubmitButton,
                FieldErrorBlock
            },

            methods: {
                getPasswordForm() {
                    return this.$refs['password-form'] || null;
                },

                getSubmitButton() {
                    return this.$refs['submit-button'] || null;
                },

                getFieldErrorBlock() {
                    return this.$refs['field-error-block'] || null;
                },

                async send() {
                    await axios.post(
                        '/password/reset',
                        new FormData(this.$el),
                        {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest',
                                'Accept': 'application/json',
                                "Content-Type": "multipart/form-data"
                            }
                        }
                    ).catch(exception => {
                        // Different from form error.
                        if (exception.response.status !== 400) {
                            this.getFieldErrorBlock().addError('internal-server-error', 'Une erreur est survenue, veuillez contacter Gorilles.');

                            return;
                        }

                        const fields = {
                            global: this,
                            '[plainPassword]': this.getPasswordForm().getConfirmationPasswordField(),
                            '[plainPassword][first]': this.getPasswordForm().getNewPasswordField(),
                            '[plainPassword][second]': this.getPasswordForm().getConfirmationPasswordField()
                        };

                        for (const error of exception.response.errors) {
                            fields[error.path].getFieldErrorBlock().addError(error.key, error.message);
                        }
                    }).then(response => window.location.replace(response.data.redirect));
                }
            },

            async beforeCreate() {
                await ske_load('api');
                await trans_load('validators');

                Vue.set(this, 'isLoaded', true)
            }
        });
    }
}
