export default class {
    /**
     * Get a cookie.
     *
     * @param {string} name
     * @returns {?string}
     */
    static get(name) {
        name += "=";

        const cookies = document.cookie.split(';');

        for (let cookie of cookies) {
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }

            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }

        return null;
    }

    /**
     * Set a cookie.
     *
     * @param {string} name
     * @param {string|number} value
     * @param {number} extraDays
     * @returns {this}
     */
    static set(name, value, extraDays) {
        const date = new Date();

        date.setTime(date.getTime() + (extraDays * 24 * 60 * 60 * 1000));

        const expires = "expires=" + date.toUTCString();

        document.cookie = name + "=" + value + ";" + expires + ";path=/";

        return this;
    }
}
