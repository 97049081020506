export class PageHome {
    static initialize() {
    }
}

//Work for Hebergement block only tablet

/*
export function resizeContainer() {
    const
        body = document.querySelector('body'),
        hebergement = document.getElementById('hebergement'),
        hebergementContainer = document.getElementById('hebergemment-container'),
        //hebergementImgContainer = document.querySelector('.hebergement-slider-img'),
        modalBlock = document.getElementsByClassName('modal-block')
    ;

    let heightDesktop = modalBlock[0].clientHeight;

    if (body.classList.contains('tablet') && !hebergement.classList.contains('hebergement-multiple')) {
        hebergementContainer.style.height = `${heightDesktop * 0.8}px`;
    }
    // hebergementImgContainer.style.width = `${heightDesktop * 0.7}px`;
}


//If bloc CONTACT is in infos pratiques
//Display none on block contact
//Display none on block footer logo
export function checkContactInInfos() {
    const contactBloc = document.getElementById('contact')
    const contactBlocLogo = document.querySelector('.footer-logo-container')
    const infosBloc = document.querySelector('.infos-pratiques .block-title h2')
    const contactInfos = document.getElementById('infos-pratiques')
    const logo = document.querySelector('.logo')
    const contactList = document.getElementsByClassName('contact-list')
    const body = document.querySelector('body')

    //Can add in-line-with-contact
    if (contactInfos.classList.contains('in-line-with-contact') || contactInfos.classList.contains('without-contact')) {
        contactBloc.style.display = 'none'
        contactBlocLogo.style.display = 'none'
        infosBloc.innerHTML = 'Infos pratiques & contacts'
    }

    //Can add in-line-with-contact
    if (contactInfos.classList.contains('without-logo-contact')) {
        contactBlocLogo.style.display = 'none'
    }

    //Can add with-logo-contact
    if (contactInfos.classList.contains('with-logo-contact')) {
        contactBlocLogo.style.display = 'block'
    }

    if (!logo) {
        return;
    }

    checkContactMultiple()

    //Can add logo-first
    if (contactInfos.classList.contains('logo-first')) {
        logo.classList.add('separator')
        logo.style.order = `${(contactList[0].children.length + 1) - contactList[0].children.length}`
        document.documentElement.style.setProperty('--right', '0' + '%')
        document.documentElement.style.setProperty('--left', 'unset')
        // contactListLast.style.paddingleft = "20px"
        logo.style.paddingRight = "50px"
        logo.style.marginRight = "40px"
        logo.style.justifyContent = "flex-start"
        if (body.classList.contains('tablet') || window.screen.width < 1375) {
            logo.style.justifyContent = "center"
            logo.style.marginRight = "0px"
        }
        if (body.classList.contains('mobile')) {
            logo.style.marginBottom = "50px"
        }
        return
    }

    //Can add logo-last
    if (contactInfos.classList.contains('logo-last')) {
        logo.classList.add('separator')
        logo.style.order = `${contactList[0].children.length}`
        document.documentElement.style.setProperty('--right', 'unset')
        document.documentElement.style.setProperty('--left', '0')
        logo.style.paddingLeft = "50px"
        logo.style.justifyContent = "flex-end"
        if ((body.classList.contains('mobile') || body.classList.contains('tablet') || window.screen.width < 1375)) {
            logo.style.justifyContent = "center"
        }
        return
    }
}

//Contact multiple width
export function checkContactMultiple() {
    const contactItems = document.querySelectorAll('.contact-item')
    const body = document.querySelector('body')
    const logo = document.querySelector('.logo')
    let indexOf = 0;

    // for each child of contactItems
    for (let contactItem of contactItems) {
        //To know the index of the child
        indexOf++

        // if 2 childs
        if (contactItems.length === 2) {
            contactItem.style.width = '50%'
            if (body.classList.contains('tablet') || body.classList.contains('mobile')) {
                contactItem.style.width = '100%'
                contactItem.style.padding = '0px'
                logo.style.padding = '0px'
            }
        }

        // if 3 childs
        if (contactItems.length === 3) {
            contactItem.style.width = '33%'
            if (body.classList.contains('tablet')) {
                contactItem.style.width = '50%'
                if (indexOf === contactItems.length) {
                    contactItem.style.width = '100%'
                    contactItem.style.padding = '0px'
                    logo.style.padding = '0px'
                }
            }
            if (body.classList.contains('mobile')) {
                contactItem.style.width = '100%'
                contactItem.style.padding = '0px'
                logo.style.padding = '0px'
            }
        }

        // if 4 childs
        if (contactItems.length === 4) {
            if (window.screen.width >= 1375) {
                contactItem.style.width = '25%'
            }
            if (window.screen.width < 1375) {
                const contactList = document.querySelector('.contact-list')
                contactList.style.flexFlow = 'row wrap'
                contactItem.style.width = '50%'
                logo.style.width = '50%'
                contactItem.style.padding = '0px'
                logo.style.padding = '0px'

                if (indexOf === 1) {
                    contactItem.style.marginBottom = '50px'
                    contactItem.style.paddingLeft = '0px'
                    contactItem.style.paddingRight = '0px'
                }
                if (indexOf === 2) {
                    contactItem.style.marginBottom = '50px'
                    contactItem.style.paddingLeft = '0px'
                    contactItem.style.paddingRight = '0px'
                }

                if (body.classList.contains('mobile')) {
                    console.log('mobile')
                    contactItem.style.width = '100%'
                    logo.style.width = '100%'
                    contactList.style.flexDirection = 'column'
                    return;
                }
            }
        }
    }
}
*/
