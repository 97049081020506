import $ from "jquery";
import {PageHome} from "../../page/home/PageHome";
import {PageRegistration} from "../../page/registration/PageRegistration";
import {PageLogin} from "../../page/security/PageLogin";
import {elementIsInViewport} from "../functions";
import Cookie from "../Cookie/Cookie";
import {PagePasswordReset} from "../../page/password/PagePasswordReset";

export class Page {
    /**
     * Initialize the page (always executed).
     *
     * @returns {void}
     */
    static initialize() {
        $(document).ready(() => {
            Page.colorSchemes();
            Page.headerMenu();
            Page.rgpd();
            Page.cssBreakpoints();

            Page.initializePage();
        });
    }

    /**
     * Initialize a specific page.
     *
     * @returns {void}
     */
    static initializePage() {
        const page = this.getPageName();

        if (page !== null) {
            const pageClasses = {
                home: PageHome,
                registration: PageRegistration,
                login: PageLogin,
                'password-reset': PagePasswordReset
            };

            pageClasses[page]?.initialize();
        }
    }

    /**
     * Get the page name from the data attribute of the <body>.
     *
     * @returns {string|null}
     */
    static getPageName() {
        return $('body').data('page') || null;
    }

    /**
     * Initialize client color schemes.
     *
     * @returns {void}
     */
    static colorSchemes() {
        const
            lightSchemeIcon = document.querySelector('link#light-scheme-icon'),
            darkSchemeIcon = document.querySelector('link#dark-scheme-icon'),
            matcher = window.matchMedia('(prefers-color-scheme: dark)')
        ;

        matcher.addEventListener('change', onUpdate);
        onUpdate();

        function onUpdate() {
            if (lightSchemeIcon !== null && darkSchemeIcon !== null) {
                if (matcher.matches) {
                    lightSchemeIcon.remove();

                    document.head.append(darkSchemeIcon);
                } else {
                    darkSchemeIcon.remove();

                    document.head.append(lightSchemeIcon);
                }
            }
        }
    }

    /**
     * Initialize header menu.
     *
     * @returns {void}
     */
    static headerMenu() {
        $(window).on('scroll', function() {
            $('.header-menu .menu-item').each(function() {
                const id = $(this).attr('data-target');

                if (elementIsInViewport('#' + id)) {
                    $('.header-menu .menu-item').removeClass('active');
                    $('.header-menu .menu-item[data-target="' + id + '"]').addClass('active');
                }
            });
        });
    }

    /**
     * Initialize RGPD modal.
     *
     * @returns {void}
     */
    static rgpd() {
        // Hide the RGPD modal if the related cookie is found.
        if (Cookie.get('rgpd-consent') !== null) {
            $('.popin-rgpd').addClass('hidden');
        }

        // Hide the RGPD modal if the user accepts them.
        $('.popin-rgpd button').on('click', function () {
            $(this).parents('.popin-rgpd').fadeOut();

            Cookie.set('rgpd-consent', 'true', 365)
        });
    }

    /**
     * Initialize the CSS breakpoint classes.
     *
     * @returns {void}
     */
    static cssBreakpoints() {
        const width = $(window).innerWidth(),
            jBody = $('body')
        ;

        if (width < 768) {
            jBody.addClass('mobile');
            jBody.removeClass('tablet');
        } else if (width >= 768 && width < 1024) {
            jBody.removeClass('mobile');
            jBody.removeClass('tablet-large');
            jBody.addClass('tablet');
        } else if (width >= 1024 && width < 1180) {
            jBody.removeClass('mobile');
            jBody.removeClass('tablet');
            jBody.addClass('tablet-large');
        } else {
            jBody.removeClass('mobile');
            jBody.removeClass('tablet');
            jBody.removeClass('tablet-large');
            jBody.addClass('desktop');
        }
    }
}


