import $ from 'jquery';

$(document).ready(function () {
    if ($('.mediatheque-photo-category-page').length) {

        if ($('.article-list-popin').find('.category-page-blocks-wrapper').length > 1) {
            $('.slick-article-popin').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: false,
                adaptiveHeight: true,
                infinite: true,
                useTransform: true,
                speed: 400,
                cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
                appendArrows: $('.modal-popin').find('.modal-arrow-container'),
                prevArrow: "<button type='button' class='slick-prev slick-arrow'><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"9.424\" height=\"16.026\" viewBox=\"0 0 9.424 16.026\">\n" +
                    "  <path id=\"Tracé_1032\" data-name=\"Tracé 1032\" d=\"M23.437,10.378a1.362,1.362,0,0,0-1.92,0L14.81,17.107a1.356,1.356,0,0,0-.042,1.87l6.609,6.63A1.355,1.355,0,1,0,23.3,23.7l-5.618-5.7,5.759-5.7A1.341,1.341,0,0,0,23.437,10.378Z\" transform=\"translate(-14.414 -9.983)\" fill=\"#fff\"/>\n" +
                    "</svg>\n</button>",
                nextArrow: "<button type='button' class='slick-next slick-arrow'><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"9.424\" height=\"16.026\" viewBox=\"0 0 9.424 16.026\">\n" +
                    "  <path id=\"Tracé_1032\" data-name=\"Tracé 1032\" d=\"M23.437,10.378a1.362,1.362,0,0,0-1.92,0L14.81,17.107a1.356,1.356,0,0,0-.042,1.87l6.609,6.63A1.355,1.355,0,1,0,23.3,23.7l-5.618-5.7,5.759-5.7A1.341,1.341,0,0,0,23.437,10.378Z\" transform=\"translate(-14.414 -9.983)\" fill=\"#fff\"/>\n" +
                    "</svg>\n</button>"
            });
        } else {
            $('.category-page-content').addClass('only-one')
        }
    }

});
