import $ from "jquery";

export class PageLogin {
    static initialize() {
        if ($('form.login-user').length) {
            // Play with select change


            $('form .form-submit').on('click', function (e) {
                var form = $(this).parents('form'),
                    required = form.find('.required'),
                    result = true;


                required.each(function () {
                    var parent = $(this).parents('.form-item');

                    if (parent.hasClass('identifiant')) {

                        var re = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                        if (!$(this).val()) {
                            result = false;
                            $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                        } else {
                            if (re.test($(this).val())) {
                                parent.removeClass('error').find('.error-message').html('')
                            } else {
                                parent.addClass('error').find('.error-message').html(parent.find('.error-message').attr('data-error-format'))
                                result = false;
                            }
                        }


                    } else if ($(this).attr('type') == 'checkbox') {
                        if (!$(this).is(":checked")) {
                            result = false;
                            $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                        } else {
                            $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
                        }
                    } else {
                        if (!$(this).val()) {
                            result = false;
                            $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                        } else {
                            $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
                        }
                    }
                });

                if (result == false) {
                    console.log('false')
                    return false;
                } else {
                    console.log('true')
                    $(this).parents('.submit-wrapper').find('.loading-spinner-container').show();
                }

            });


            // REMOVE ERROR MESSAGE ON INPUT
            $('.form-item input,.form-item textarea').on('input', function () {
                if ($(this).val()) {
                    $(this).parents('.form-item').removeClass('error').find('.error-message').html('')
                } else {
                    $(this).parents('.form-item').addClass('error').find('.error-message').html($(this).parents('.form-item').find('.error-message').attr('data-error'))
                }
            });

            // DETECT VALID SUBMISSIONS MESSAGE AND APEND IN DIV
            if ($('div[data-drupal-messages]').length) {
                $('div[data-drupal-messages] .messages:not(.messages--warning) p').each(function () {
                    $('form').find('.validation-form').removeClass('hide').append($(this).html())
                })
            }

            if ($('.path-user').length) {
                $('form .form-item').each(function () {
                    var label = $(this).find('label').text()
                    $(this).find('input').attr('placeholder', label)
                })
            }

            $('form .form-item .form-textarea').removeAttr('aria-required')
        }
        // function mobileShit ( refDOM ) {
        //     console.log ( `refDOM.clientHeight : ${refDOM.clientHeight}` ) ;
        //     console.log ( `window.innerHeight : ${window.innerHeight}` ) ;
        //     if ( refDOM.clientHeight !== window.innerHeight ) { // accounting for mobile
        //         refDOM.style.setProperty('--vhLogin', window.innerHeight + 'px' ) ;
        //     }
        // }
        // const loginBody = document.querySelector('body.login')
        // mobileShit(loginBody);
    }
}

