<template>
    <ul class="field-errors-list" v-if="hasErrors">
        <li v-for="error in errors">{{ error }}</li>
    </ul>
</template>


<script>
import Vue from "vue/dist/vue";
import {object_length} from "../../../common/functions";

export default Vue.component('FieldErrorBlock', {
    data() {
        return {
            errors: {}
        };
    },

    computed: {
        hasErrors() {
            return (object_length(this.errors) > 0);
        }
    },

    methods: {
        addError(key, message) {
            Vue.set(this.errors, key, message);
        },

        removeError(key) {
            delete this.errors[key];
        },

        clearErrors() {
            Vue.set(this, 'errors', {});
        }
    }
});
</script>
