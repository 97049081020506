<template>
    <div class="loading-spinner-container">
        <div class="loading-spinner-wrapper">
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue/dist/vue";

export default Vue.component('SpinnerLoader', {});
</script>
