<template>
    <div class="loader-dots-container">
        <div class="loader-dots"></div>
    </div>
</template>

<script>
import Vue from "vue/dist/vue";

export default Vue.component('DotsLoader', {});
</script>
