var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.hasErrors
    ? _c(
        "ul",
        { staticClass: "field-errors-list" },
        _vm._l(_vm.errors, function (error) {
          return _c("li", [_vm._v(_vm._s(error))])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }